var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.surveyInfo?.surveyId)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '2-22-80',
    data: { surveyId: _vm.surveyInfo?.surveyId },
    callback: _vm.surveyExposeCallback
  }),expression:"{\n    id: '2-22-80',\n    data: { surveyId: surveyInfo?.surveyId },\n    callback: surveyExposeCallback\n  }"}],staticClass:"survey-question",class:{ 'survey-question-background': _vm.currentScore >= 0 && _vm.currentScore < 3 },style:(_vm.compStyle)},[(!_vm.isScore || ['ShowOneAuto', 'NotShow'].includes(_vm.deliveryStyle))?_c('div',{staticClass:"survey-question__main-title",class:{ 
      'survey-question__main-title-height': _vm.deliveryStyle === 'ShowOneClick',
      'survey-question__main-title-margin': _vm.deliveryStyle === 'NotShow' 
    }},[_vm._v("\n    "+_vm._s(_vm.surveyInfo?.mainTitle ? _vm.surveyInfo?.mainTitle : 'How satisfied are you with these recommendations?')+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.isScore && _vm.deliveryStyle === 'ShowOneClick')?_c('div',{directives:[{name:"tap",rawName:"v-tap",value:({
      id: '2-22-81',
      data: { surveyId: _vm.surveyInfo?.surveyId }
    }),expression:"{\n      id: '2-22-81',\n      data: { surveyId: surveyInfo?.surveyId }\n    }"}],staticClass:"survey-question__score-describe survey-question__score-describe-column",on:{"click":_vm.handleClick}},[_c('img',{attrs:{"src":_vm.scoreInfo[_vm.currentScore].icon,"alt":_vm.scoreInfo[_vm.currentScore].title}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.scoreInfo[_vm.currentScore].title))])]):_vm._e(),_vm._v(" "),(['ShowOneClick', 'ShowOneAuto'].includes(_vm.deliveryStyle))?_c('div',{staticClass:"survey-question__score-container"},_vm._l((_vm.scoreInfo),function(scoreItem,scoreIndex){return _c('div',{directives:[{name:"tap",rawName:"v-tap",value:({
        id: '2-22-82',
        data: { feedback_star: scoreIndex + 1 }
      }),expression:"{\n        id: '2-22-82',\n        data: { feedback_star: scoreIndex + 1 }\n      }"}],key:scoreItem?.title + scoreIndex,staticClass:"survey-question__score-info",on:{"click":function($event){return _vm.handleClickScore(scoreIndex)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentScore < scoreIndex),expression:"currentScore < scoreIndex"}],staticClass:"survey-question__star-img",attrs:{"src":"https://img.ltwebstatic.com/images3_ccc/2024/09/06/60/172560936468d9c0f9781ef943e99ba64bc014e943.png","alt":"star icon unselected"}}),_vm._v(" "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentScore >= scoreIndex),expression:"currentScore >= scoreIndex"}],staticClass:"survey-question__star-img",attrs:{"src":"https://img.ltwebstatic.com/images3_ccc/2024/09/06/60/17256093648ed22d010b88f8f25d36d1cb3ee26636.png","alt":"star icon selected"}})])}),0):_vm._e(),_vm._v(" "),(_vm.isScore && _vm.deliveryStyle === 'ShowOneAuto')?_c('div',{staticClass:"survey-question__score-describe survey-question__score-describe-row"},[_c('img',{attrs:{"src":_vm.scoreInfo[_vm.currentScore].icon,"alt":_vm.scoreInfo[_vm.currentScore].title}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.scoreInfo[_vm.currentScore].title))])]):_vm._e(),_vm._v(" "),(!_vm.isScore && ['ShowOneClick', 'ShowOneAuto'].includes(_vm.deliveryStyle))?_c('div',{staticClass:"survey-question__sub-title"},[_vm._v("\n    "+_vm._s(_vm.surveyInfo?.subTitle ? _vm.surveyInfo?.subTitle : 'Tap a star to rate')+"\n  ")]):_vm._e(),_vm._v(" "),((_vm.isScore && _vm.deliveryStyle === 'ShowOneClick') || _vm.deliveryStyle === 'NotShow' )?_c('div',{directives:[{name:"tap",rawName:"v-tap",value:({
      id: '2-22-81',
      data: { surveyId: _vm.surveyInfo?.surveyId }
    }),expression:"{\n      id: '2-22-81',\n      data: { surveyId: surveyInfo?.surveyId }\n    }"}],staticClass:"survey-question__button",on:{"click":_vm.handleClick}},[_c('span',[_vm._v(_vm._s(_vm.buttonText))]),_vm._v(" "),_c('span',{staticClass:"survey-question__button-icon"},[_vm._v(">")])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }