<template>
  <div
    v-if="surveyInfo?.surveyId"
    v-expose="{
      id: '2-22-80',
      data: { surveyId: surveyInfo?.surveyId },
      callback: surveyExposeCallback
    }"
    :style="compStyle"
    class="survey-question"
    :class="{ 'survey-question-background': currentScore >= 0 && currentScore < 3 }"
  >
    <!-- 主标题  -->
    <div
      v-if="!isScore || ['ShowOneAuto', 'NotShow'].includes(deliveryStyle)"
      class="survey-question__main-title"
      :class="{ 
        'survey-question__main-title-height': deliveryStyle === 'ShowOneClick',
        'survey-question__main-title-margin': deliveryStyle === 'NotShow' 
      }"
    >
      {{ surveyInfo?.mainTitle ? surveyInfo?.mainTitle : 'How satisfied are you with these recommendations?' }}
    </div>
    <!-- 已评分 - 评分描述 ShowOneClick 类型  -->
    <div
      v-if="isScore && deliveryStyle === 'ShowOneClick'"
      v-tap="{
        id: '2-22-81',
        data: { surveyId: surveyInfo?.surveyId }
      }"
      class="survey-question__score-describe survey-question__score-describe-column"
      @click="handleClick"
    >
      <img
        :src="scoreInfo[currentScore].icon"
        :alt="scoreInfo[currentScore].title"
      />
      <span>{{ scoreInfo[currentScore].title }}</span>
    </div>

    <!-- 评分星星 -->
    <div
      v-if="['ShowOneClick', 'ShowOneAuto'].includes(deliveryStyle)"
      class="survey-question__score-container"
    >
      <div
        v-for="(scoreItem, scoreIndex) in scoreInfo"
        :key="scoreItem?.title + scoreIndex"
        v-tap="{
          id: '2-22-82',
          data: { feedback_star: scoreIndex + 1 }
        }"
        class="survey-question__score-info"
        @click="handleClickScore(scoreIndex)"
      >
        <img
          v-show="currentScore < scoreIndex"
          class="survey-question__star-img"
          src="https://img.ltwebstatic.com/images3_ccc/2024/09/06/60/172560936468d9c0f9781ef943e99ba64bc014e943.png"
          alt="star icon unselected"
        />
        <img
          v-show="currentScore >= scoreIndex"
          class="survey-question__star-img"
          src="https://img.ltwebstatic.com/images3_ccc/2024/09/06/60/17256093648ed22d010b88f8f25d36d1cb3ee26636.png"
          alt="star icon selected"
        />
      </div>
    </div>

    <!-- 已评分 - 评分描述 自动跳转类型 ShowOneAuto -->
    <div
      v-if="isScore && deliveryStyle === 'ShowOneAuto'"
      class="survey-question__score-describe survey-question__score-describe-row"
    >
      <img
        :src="scoreInfo[currentScore].icon"
        :alt="scoreInfo[currentScore].title"
      />
      <span>{{ scoreInfo[currentScore].title }}</span>
    </div>

    <!-- 副标题 - 未评分  -->
    <div
      v-if="!isScore && ['ShowOneClick', 'ShowOneAuto'].includes(deliveryStyle)"
      class="survey-question__sub-title"
    >
      {{ surveyInfo?.subTitle ? surveyInfo?.subTitle : 'Tap a star to rate' }}
    </div>

    <!-- 跳转问卷页面按钮 -->
    <div
      v-if="(isScore && deliveryStyle === 'ShowOneClick') || deliveryStyle === 'NotShow' "
      v-tap="{
        id: '2-22-81',
        data: { surveyId: surveyInfo?.surveyId }
      }"
      class="survey-question__button"
      @click="handleClick"
    >
      <span>{{ buttonText }}</span>
      <span class="survey-question__button-icon">></span>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue'
import {  Toast } from '@shein/sui-mobile'
import { daEventCenter } from 'public/src/services/eventCenter'
daEventCenter.addSubscriber({ modulecode: '2-22-80' })
daEventCenter.addSubscriber({ modulecode: '2-22-81' })
daEventCenter.addSubscriber({ modulecode: '2-22-82' })
export default defineComponent({
  name: 'FashionStoreContent',
  components: {
  },
  props: {
    // 接口返回的信息
    surveyInfo: {
      type: Object,
      default: (() => {})
    },
    // 多语言， 取自i18n survey_question.js
    language: {
      type: Object,
      default: (() => {})
    },
    // 内容体样式 width、margin-bottom
    compStyle: {
      type: Object,
      default: (() => {})
    },
    constantData: {
      type: Object,
      default: (() => {})
    },
    // 组一：搜索页、真实列表页、选品列表页、商详推荐、店铺列表页；组二：首页信息流首tab
    groupNum: {
      type: Number,
      default: 1
    },
  },
  setup (props) {
    const scoreInfo = ref([
      {
        title: props.language.SHEIN_KEY_PWA_33591 || 'Very Dissatisfied',
        icon: 'https://img.ltwebstatic.com/images3_ccc/2024/08/30/99/1725005942281e36230eae8569264731cdbec17b8c.png',
      },
      {
        title: props.language.SHEIN_KEY_PWA_33592 || 'Dissatisfied',
        icon: 'https://img.ltwebstatic.com/images3_ccc/2024/08/30/90/172500748641222de61e54f09bc18841b775412efb.png',
      },
      {
        title: props.language.SHEIN_KEY_PWA_33593 || 'Neutral',
        icon: 'https://img.ltwebstatic.com/images3_ccc/2024/08/30/90/1725007486a177eeb80006c8e7d78592d996ba9f5f.png',
      },
      {
        title: props.language.SHEIN_KEY_PWA_33594 || 'Satisfied',
        icon: 'https://img.ltwebstatic.com/images3_ccc/2024/08/30/90/172500748685edee0745ac4a2b585a570ab285a5cf.png',
      },
      {
        title: props.language.SHEIN_KEY_PWA_33595 || 'Very Satisfied',
        icon: 'https://img.ltwebstatic.com/images3_ccc/2024/08/30/90/1725007486b9e036fb7420be0d4753aa243a69d172.png',
      },
    ])
    const buttonText = ref(props.language.SHEIN_KEY_PWA_33596 || 'Help us improve')

    // 内容体样式 - 外露1题点击-ShowOneClick、外露1题自动-ShowOneAuto、无外露-NotShow
    const deliveryStyle = computed(() => {
      return props.surveyInfo?.deliveryStyle || 'ShowOneClick'
    })
    // 当前评分等级
    const currentScore = ref(-1)
    // 是否已评分
    const isScore = ref(false)

    // 点击评分
    const handleClickScore = async (scoreIndex) => {
      currentScore.value = scoreIndex
      isScore.value = true
      deliveryStyle.value === 'ShowOneAuto' && Toast({
        htmlString: `<div style="padding: 0.32rem;">${props.language.SHEIN_KEY_PWA_33665}</div>`,
        duration: 800,
        onClose: () => {
          handleClick()
        },
      })
      const surveyIds = JSON.parse(localStorage.getItem('surveyIds') || null) || []
      if (props.surveyInfo?.surveyId && !surveyIds.includes(props.surveyInfo.surveyId)) {
        surveyIds.push(props.surveyInfo.surveyId)
        localStorage.setItem('surveyIds', JSON.stringify(surveyIds))
      }
    }

    const handleClick = async () => {
      const { surveyId } = props.surveyInfo
      const hrefTarget = props.surveyInfo?.webSurveyUrl
      const { langPath } = props.constantData
      let url = `${langPath}/survey/${hrefTarget}?starRange=${currentScore.value + 1}&surveyId=${surveyId}`
      window.location.href = url
    }

    const surveyExposeCallback = () => {
      const surveyExposeInfo = JSON.parse(localStorage.getItem('surveyExposeInfo') || null) || []
      surveyExposeInfo.push({
        exposeTime: new Date().getTime(),
      })
      // 限制存储的数量为100
      surveyExposeInfo.length > 100 && surveyExposeInfo.shift()
      localStorage.setItem('surveyExposeInfo', JSON.stringify(surveyExposeInfo))
      sessionStorage.setItem(`survey_group${props.groupNum}`, 'true')
      props.surveyInfo.exposureMark = true
      appEventCenter.$emit('surveyComponentExposed', {
        surveyInfo: props.surveyInfo,
      })
    }

    return {
      isScore,
      deliveryStyle,
      scoreInfo,
      currentScore,
      buttonText,
      handleClick,
      handleClickScore,
      surveyExposeCallback,
    }
  },
})
</script>


<style lang="less">
.survey-question {
  background: #FFF;
  border-radius: 0.1067rem;
  padding: 0.32rem 0.16rem;
  background-image: url("https://shein.ltwebstatic.com/svgicons/2024/08/29/17249206093483791264.svg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  text-align: center;
  .survey-question__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.08rem 0.2133rem;
    background-color: #FFF;
    color: #337EFF;
    border: 1px solid #337EFF;
    border-radius: 0.6667rem;
    // font-family: "SF UI Display";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 0.3733rem;
    animation: blink 0.8s linear 1.6s 3;
  }
  @keyframes blink {
    0%, 100% {
      background-color: #FFF;
    }
    50% {
      background: #D9EAFF;
    }
  }
  &__button-icon {
    margin-left: 2px;
  }
  &__main-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: #000;
    text-align: center;
    // font-family: "SF UI Display";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__main-title-height {
    min-height: 0.96rem;
  }
  &__main-title-margin {
    margin-bottom: 0.2133rem;
  }
  &__sub-title {
    color: var(---sui_color_gray_dark2, #666);
    text-align: center;
    // font-family: "SF UI Display";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    min-height: 0.5867rem;
    display: inline-flex;
    align-items: center;
  }
  &__score-container {
    display: flex;
    justify-content: space-between;
    margin: 0.2133rem 0;
  }
  &___score-info {
    display: flex;
  }
  &__star-img {
    width: 0.8rem;
    height: 0.8rem;
  }
  &__score-describe {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 0.5333rem;
      height: 0.5333rem;
      margin-right: 0.1333rem;
    }
    span {
      overflow: hidden;
      color: #000;
      text-align: center;
      text-overflow: ellipsis;
      // font-family: "SF UI Display";
      font-size: 0.32rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
  }
  &__score-describe-column {
    flex-direction: column;
    span {
      font-weight: 700;
    }
    img {
      margin-bottom: 0.05333rem;
      margin-right: 0;
    }
  }
  &__score-describe-row {
    min-height: 0.5867rem;
    span {
      line-height: 0.48rem;
    }
  }
}
.survey-question-background {
  background-image: url("https://shein.ltwebstatic.com/svgicons/2024/08/30/17250054383749435206.svg");
}
</style>

